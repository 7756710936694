/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Legend {
    position: relative;
    padding: 10px;
    background: var(--white);
    z-index: 700;
    margin: 10px;
    border-radius: var(--border-radius);
    border: 1px solid var(--medium);
    display: flex;
    float: right;
    flex-direction: column;
    gap: 5px;
}

.Legend:hover {
    cursor: default;
}

.Legend .Bin {
    display: flex;
    gap: 5px;
}

.Legend .Bin .Label {
    flex: 1;
    background: #f5f5f5;
    padding: 3px 5px;
    display: flex;
    gap: 5px;
}

.Legend .Bin .Label span {
    font-family: var(--font-control);
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    flex: 1;
}

.Legend .Bin .Colour {
    /*-webkit-text-stroke: 1px white;*/
    display: block;
    border: 1px solid var(--light);
    color: var(--dark);
    height: 25px;
    width: 25px;
}
