/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Pricing table {
    border-color: transparent;
    border-collapse: separate;
    border-spacing: 20px 0 !important;
}

.Pricing th.tableHeader {
    border-radius: 10px 10px 0 0;
    text-align: center;
    padding: 0;
    font-size: 14px;
}

.Pricing th.tableHeader h2 {
    margin: 10px 0 0 !important;
}

.Pricing th.tableHeader button,
.Pricing th.tableHeader a {
    background: var(--white) !important;
    border: none !important;
    color: var(--dark) !important;
}

.Pricing .Button {
    border-color: var(--white) !important;
    margin: 0 auto;
}

.Pricing td {
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid var(--medium);
}

.Pricing td img {
    height: 25px;
}

.Pricing td:first-child {
    border-bottom: none;
    background: none !important;
}

.Pricing td:first-child span {
    border: 1px solid var(--medium);
    margin: 5px 0;
    padding: 5px 10px;
    display: block;
    border-radius: 10px;
}

.Pricing td:nth-child(2) {
    border-left: 1px solid var(--secondary);
    border-right: 1px solid var(--secondary);
}

.Pricing td:nth-child(3) {
    border-left: 1px solid var(--tertiary);
    border-right: 1px solid var(--tertiary);
}

.Pricing tr:nth-last-child(2) td {
    border-bottom: none;
}

.Pricing tr:last-child td,
.Pricing tr:last-child:hover td {
    border-bottom: none;
    border-radius: 0 0 10px 10px;
    padding: 0 !important;
    height: 20px;
}
