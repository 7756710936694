.YourDetails {
    padding: 30px 0 0;
}

.YourDetails .FormGroup {
    margin: 0 1rem 1rem;
}

.YourDetails h2 {
    margin: 40px 0 20px;
}
