/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.FMSelect {
    min-width: 200px;
    margin: 0;
    text-align: left;
}

.FMSelect * {
    border-radius: 0;
    border-color: var(--light);
}

.FMSelect svg path {
    color: var(--white);
}
