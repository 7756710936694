/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Intro .buttons {
    width: fit-content;
    margin: 0 auto 50px;
    display: flex;
}

.Intro .buttons > * {
    flex: 1;
    margin: 0 10px !important;
}

.Intro .videoContainer {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(100% - 100px);
    margin: 0 -5px;
    padding: 50px 0 !important;
}
