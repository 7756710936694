/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Sidebar {
    flex: 0;
    background: var(--bg-quaternary);
    border-right: 4px solid var(--white);
}

.Sidebar .SidebarButton {
    border-color: var(--tertiary) !important;
    float: left;
    margin: 0;
}

.Sidebar .SidebarButton a {
    background: var(--quaternary) !important;
}

.Sidebar .SidebarButton a button {
    background: var(--quaternary) !important;
    color: var(--white) !important;
    width: auto !important;
    min-width: 50px !important;
    font-size: 12px !important;
    height: 28px !important;
    padding: 0 10px !important;
}
