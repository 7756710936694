.GraphView .recharts-text {
    font-size: 13px;
}

.GraphView .recharts-surface {
    position: absolute;
}

.GraphView .bar-chart {
    width: 100%;
}
