/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Profile {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.Profile .userProperty {
    width: 100%;
    height: 40px;
    line-height: 40px;
}

.Profile .Buttons {
    margin: 30px 0 0;
    display: flex;
}

.Profile .Buttons button {
    width: fit-content !important;
    padding: 0 10px;
}

.Profile .userProperty * {
    font-size: 13px;
    float: left;
}

.Profile .userPropertyKey {
    font-weight: bold;
    width: 200px;
}

.Profile .userPropertyValue {
    font-weight: normal;
    margin: 0 5px 0 0;
}

.Profile .userProperty input[type="checkbox"] {
    margin: 14px 0;
}

.Profile userPropertyValue div {
    height: 10px;
}

.Profile .userProperty .tick {
    color: var(--accent);
    cursor: default;
}

.Profile .link {
    color: var(--tertiary);
}

.Profile .link:hover {
    color: var(--quaternary);
    cursor: pointer;
}
