.Start .Buttons {
    margin: 20px auto;
    width: fit-content;
    display: flex;
    gap: 5px;
}

.Start .Buttons .ExploreButton button {
    width: fit-content !important;
    padding: 0 15px;
}
