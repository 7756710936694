/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.GraphView {
    flex: 1;
}

.Dashboard .Graph div {
    flex: 1;
}

.Tooltip {
    background: var(--white);
    padding: 10px 10px 0;
    border: 1px solid var(--light);
    font-size: 13px;
}
