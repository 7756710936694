/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.FMTrans {
    display: inline-block;
    margin: 0;
}

.FMTrans p {
    margin: 0 !important;
}
