/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.CurrentTimeUnitSlider {
    width: calc(100% - 50px);
    height: 40px;
    padding: 5px 25px 0;
    border-radius: 10px;
    border: 1px solid var(--quaternary);
    /* slider is "behind" the map to allow map tooltip, which inherits map z-index, to be displayed on top of the slider. */
    z-index: 400 !important;
    margin: 10px 0 0;
}

.CurrentTimeUnitSlider span.Info {
    margin: 8px 0 0;
    display: block;
    font-size: 13px;
}
