/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.SequentialMenu .Breadcrumbs {
    display: flex;
    padding: 0 60px;
    gap: 20px;
    margin: 0;
}

.SequentialMenu .Breadcrumbs .back,
.SequentialMenu .Breadcrumbs .next {
    cursor: pointer;
    text-align: center;
    flex-grow: 0.2;
    font-weight: bold;
}

.SequentialMenu .Breadcrumbs .back {
    margin-right: 20px;
}

.SequentialMenu .Breadcrumbs .next {
    margin-left: 20px;
}

.SequentialMenu .Breadcrumbs .back img,
.SequentialMenu .Breadcrumbs .next img {
    filter: brightness(999%) brightness(999%);
    margin: 0;
}

.SequentialMenu .Breadcrumbs .back img {
    rotate: 180deg;
}

.SequentialMenu .Breadcrumbs li {
    flex: 1;
    display: inline-block;
    list-style: none;
    border-radius: var(--border-radius);
    background: var(--light);
    color: var(--medium);
    font-family: var(--font-heading);
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    vertical-align: middle;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}

.SequentialMenu .Breadcrumbs li::before {
    content: "";
}

.SequentialMenu .Breadcrumbs li.notActive {
    background: var(--white);
    color: var(--secondary);
    outline: 2px solid var(--secondary);
}

.SequentialMenu .Breadcrumbs li.active,
.SequentialMenu .Breadcrumbs li.done {
    background: var(--secondary);
    color: var(--primary);
    outline: 2px solid var(--primary);
}

.SequentialMenu .Breadcrumbs li img {
    height: 15px;
    margin: -2px 10px 0 0;
    filter: brightness(999%) brightness(999%) grayscale(100%);
    float: right;
    margin: 17px 0;
}

.SequentialMenu .Breadcrumbs li.active img {
    filter: grayscale(100%) brightness(999%);
}

.SequentialMenu .Breadcrumbs li.inactive {
    cursor: default;
    background-color: var(--light);
}

.SequentialMenu .Breadcrumbs li.inactive:hover {
    color: var(--white);
}
