/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Tutorial .Content {
    margin: 0;
}

.Tutorial .Buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 50px 0 0;
}

.Tutorial .LastButton a button {
    width: max-content !important;
    padding: 0 10px !important;
}

.Tutorial ul {
    list-style: none;
    margin: 20px 0 0;
}

.Tutorial ul li::before {
    content: "\25AA";
    color: var(--tertiary);
    display: inline-block;
    width: 1em;
    margin: 0 10px 5px -20px;
}

.Tutorial .Tabs img {
    display: none;
}

.Tutorial .Tabs ul {
    padding: 0;
}

.Tutorial .Tabs ul li {
    cursor: pointer;
}

.Tutorial .Tabs li:first-child {
    display: none;
}

.Tutorial strong {
    color: var(--quaternary);
    font-weight: bold;
    font-family: var(--font-control);
}

.Tutorial ol {
    margin: 25px 0;
}

.Tutorial ol li {
    margin: 5px 0;
}

.Tutorial ol li ol {
    margin: 0;
    list-style: lower-alpha;
}

.Tutorial ol li ol li {
    margin: 0;
}

.Tutorial .Pictures {
    display: flex;
    gap: 50px;
}

.Tutorial .Pictures Container {
    border: 20px;
    flex-direction: row;
}

.Tutorial .Pictures img {
    margin: 5px;
    min-width: 200px;
    max-width: 650px;
    min-height: 100px;
}
