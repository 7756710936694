/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.FMButton {
    margin: 0 10px;
    border-radius: 9px !important;
    border: 2px solid var(--white);
    width: fit-content;
}

.FMButton a,
.FMButton a button {
    width: auto !important;
    display: block;
    border-radius: 4px !important;
    margin: 0 !important;
    padding: 0 10px;
}

.FMButton button,
.FMButton button:focus {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 35.5px !important;
    min-width: 150px !important;
    padding: 0;
    border: none;
    margin: 0 !important;
    display: inline-block;
}

.FMButton.primary {
    border-color: var(--primary);
}
.FMButton.secondary {
    border-color: var(--secondary);
}

.FMButton.outline.primary {
    border-color: var(--secondary);
}
.FMButton.outline.secondary {
    border-color: var(--primary);
}

.FMButton a:hover {
    filter: brightness(95%) !important;
}

.FMButton.primary a,
.FMButton.primary button {
    color: var(--secondary) !important;
    background: var(--primary) !important;
}

.FMButton.secondary a,
.FMButton.secondary button {
    color: var(--primary) !important;
    background: var(--secondary) !important;
}

.FMButton.primary.outline a,
.FMButton.primary.outline button {
    border-color: var(--secondary) !important;
}

.FMButton.secondary.outline a,
.FMButton.secondary.outline button {
    border-color: var(--primary) !important;
}

.FMButton.primary.inverted,
.FMButton.primary.inverted a,
.FMButton.primary.inverted button {
    color: var(--primary) !important;
    background: var(--white) !important;
    border-color: var(--primary) !important;
}

.FMButton.secondary.inverted,
.FMButton.secondary.inverted a,
.FMButton.secondary.inverted button {
    color: var(--secondary) !important;
    background: var(--white) !important;
    border-color: var(--secondary) !important;
}

.FMButton.primary.inactive,
.FMButton.secondary.inactive,
.FMButton.inactive a,
.FMButton.primary.inactive a,
.FMButton.secondary.inverted.inactive a,
.FMButton.inactive button,
.FMButton.secondary.inactive button,
.FMButton.secondary.inverted.inactive button {
    cursor: default;
    color: var(--white) !important;
    background-color: var(--light) !important;
    border-color: var(--light) !important;
}

.FMButton.inactive button:hover {
    color: var(--white);
    filter: none !important;
}
