// This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
// If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.

.TableView .react-bootstrap-table th.sortable .dropup .caret::before {
    content: "⏶";
    color: var(--quaternary);
}

.TableView .react-bootstrap-table th.sortable .caret::before {
    content: "⏷";
    color: var(--quaternary);
}

.TableView .react-bootstrap-table th.sortable .order .dropup .caret::before {
    content: "⏶";
    color: var(--medium);
}

.TableView .react-bootstrap-table th.sortable .order .dropdown .caret::before {
    content: "⏷";
    color: var(--medium);
}

.TableView .react-bootstrap-table thead {
    background: var(--light);
}

.TableView .table > :not(:first-child) {
    border-top: 2px solid var(--quaternary) !important;
}
