/*
    This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
    If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.
*/

.Privacy .Accordion {
    margin: 30px 0;
}

.Privacy .Accordion * {
    margin: 0;
}

.Privacy .Accordion button {
    padding: 0 10px;
    width: calc(100% - 20px);
}

.Privacy .Accordion button::after {
    filter: brightness(999%) brightness(999%) !important;
}

.Privacy .Accordion button:hover::after {
    filter: none;
}

.Privacy img.Hint {
    height: 15px;
    margin: -2px 0 0 5px;
}
